import React from 'react';
import {Button} from "@material-ui/core";

export default function ButtonApp (props) {
    const { variant, color } = props;

    return(
        <Button variant={variant ?? 'contained'} color={color ?? 'primary'} {...props}>{props.name}</Button>
    );
}
