import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import primaryValues from '../constants/Values.js';
import '../App.css';
import '../style/Center.css';
import '../../node_modules/bootstrap-css-only/css/bootstrap.css';
import moment from 'moment';
import {Scrollbars} from 'react-custom-scrollbars';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import {Autocomplete, createFilterOptions} from '@material-ui/lab';
import TextField from '@material-ui/core/TextField';
import ReactTooltip from 'react-tooltip';
import * as iconsAi from "react-icons/ai";
import * as Icons from "react-icons/md";
import {MdCancel, MdErrorOutline} from "react-icons/md";
import * as IconsFa from "react-icons/fa";
import * as IconsFi from "react-icons/fi";
import Paper from "@material-ui/core/Paper";
import Collapse from "@material-ui/core/Collapse";
import NewOrder from './NewOrder';
import NewClub from './NewClub';
import NewUser from './NewUser';
import VaucherImage from './VaucherImage';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {IoIosMail, IoIosMailOpen, IoIosMailUnread, IoIosRefresh} from "react-icons/io";
import * as IconsGi from "react-icons/gi";
import {BiSort, BiSortDown, BiSortUp} from "react-icons/bi";
import RowsTable from './RowsTable';
import {modalServices} from '../services';
import Login from "../pages/Login";

const defaultFilterOptions = createFilterOptions({
    limit: 50
});

/**
 *
 * Helpers functions
 *
 */

function sortByLetters(param) {
    return (a, b) => {
        const valb = a[param];
        const vala = b[param];
        return vala.toUpperCase() > valb.toUpperCase() ? -1 : vala.toUpperCase() < valb.toUpperCase() ? 1 : 0;
    };
}

function sortByNum(param) {
    return (a, b) => parseInt(a[param]) - parseInt(b[param]);
}

function sortByDate(up = 'date-up') {
    return (a, b) => {
        const result = parseInt(fixDate(a)) - parseInt(fixDate(b));
        return up === 'date-up' ? result : -(result);
    };
}

function formatDate(order) {
    if ((order.date + '').toString().length > 10) {
        order.date = order.date.toString().substring(0, 10);
    }

    const momentDate = moment(order.date);
    let date = order.date.indexOf('/') !== -1 ? order.date : momentDate.isValid() ? momentDate.format('DD/MM/YYYY') : undefined;

    if (!order.date || order.date === '' || order.date === '01/01/1930' || order.date.indexOf('undefined') !== -1 || date === undefined || order.date === undefined || (order.date && order.date.toString().length < 8)) {
        date = '01/01/2030';
    }

    order.date = date;
    return order;
}

function fixDate(order) {
    const da = order.date.split('/');
    if (da[0].length === 1) da[0] = '0' + da[0];
    if (da[1].length === 1) da[1] = '0' + da[1];
    return da.reverse().join('');
}

function formatOrderType(order) {
    let vala = order.type;
    if (vala.toString().length < 2) vala = 'show';
    if (vala.toString() === 'service') vala = 'transfer';
    return vala;
}

/**
 * END Helpers functions
 */

const FreeSearchBar = (props) => {
    const [value, setValue] = useState('')

    const onChange = (val) => {
        let searchBy = typeof val === 'string' ? val : val.target.value;
        setValue(searchBy);
    };

    const onSearch = (term) => {
        props.setSearchTerm(term ?? value);
        props.searchOrders(term ?? value, 'free');
    }

    return (
        <div style={{
            overflow: 'hidden',
            maxWidth: '95vw',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex'
        }}>
            <div id="search-bar" style={{display: 'flex', alignItems: 'flex-end', flexDirection: 'row'}}>
                <div className={'menu-bar-autocomplete'} style={{width: 228}}>
                    <TextField InputLabelProps={{style: {color: '#ebebeb', fontWeight: 'bold'}}}
                               value={value}
                               onChange={(e) => {
                                   onChange(e.target.value);
                                   onSearch(e.target.value);
                               }} label={'Free Search'}
                        // onKeyDown={e => e.key === 'Enter' && onSearch()}
                               fullWidth
                    />
                </div>
                <button className="menu-btn" data-place='bottom' data-tip="Free Search" disabled={props.modalActive}
                        onClick={() => onSearch()}
                        style={{margin: 4, paddingRight: 8, paddingLeft: 8}}>
                    <IconsFa.FaSearch style={{fontSize: 18}}/>
                </button>
                <button className="menu-btn" data-place='bottom' data-tip="Clear search" disabled={!props.hasSearchResults}
                        onClick={() => {
                            onChange('');
                            onSearch('');
                        }}
                        style={{margin: 4, paddingRight: 8, paddingLeft: 8}}>
                    <IconsFa.FaTimes style={{fontSize: 18}}/>
                </button>
            </div>
        </div>)
}

const SearchBar = (props) => {
    const [searchMode, setSearchMode] = useState(false)
    const [searchType, setSearchType] = useState({name: ''});

    useEffect(() => {
        if (!searchMode) props.setSearchMode('free');
    }, [searchMode]);

    return <div style={{
        flexDirection: 'row',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 15
    }}>
        <button style={{marginTop: 13, marginRight: 10}} className="menu-btn" data-place='bottom' data-tip="Search Mode"
                disabled={props.modalActive} onClick={() => {
            setSearchMode(r => !r)
        }}>
            <IconsFa.FaExchangeAlt style={{fontSize: 18}}/></button>
        {!searchMode ? <FreeSearchBar {...props} /> : (<div style={{
            overflow: 'hidden',
            maxWidth: '95vw',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex'
        }}>
            <div id="search-bar" style={{display: 'flex', alignItems: 'flex-end', flexDirection: 'row'}}>
                <div className={'menu-bar-autocomplete'} style={{width: 110}}>
                    <Autocomplete
                        data-tip="Select Search Mode"
                        options={[{name: 'ID'}, {name: 'Name'}, {name: 'Club'}, {name: 'Mobile'}, {name: 'Email'}, {name: 'Date'}]}
                        getOptionLabel={option => option.name}
                        color={'red'}
                        value={searchType}
                        onChange={(e, value) => {
                            setSearchType(value ?? {name: ''});
                            props.setSearchSuggestions(e.target.textContent);
                        }}
                        renderInput={params => (
                            <TextField {...params}
                                       InputLabelProps={{style: {color: '#ebebeb', fontWeight: 'bold'}}}
                                       label={'Search'} fullWidth/>
                        )}/>
                </div>
                <div className={'menu-bar-autocomplete'} style={{width: 210}}>
                    <Autocomplete
                        filterOptions={defaultFilterOptions}
                        data-tip={props.searchTerm === '' ? 'You Must Select Mode' : "Enter Order Info"}
                        options={props.searchSuggestions}
                        getOptionLabel={option => option?.name}
                        onChange={(e) => {
                            props.setSearchTerm(e.target?.textContent);
                            props.searchOrders(e.target?.textContent, e.target?.textContent ? searchType?.name : 'none');
                        }}
                        renderInput={params => (
                            <TextField {...params}
                                       InputLabelProps={{style: {color: '#ebebeb', fontWeight: 'bold'}}}
                                       label={searchType.name}
                            />
                        )}/>
                </div>
            </div>
        </div>)}
    </div>
}

class Center extends React.Component {
    state = {
        user_permission: this.props.initialState.user_permission,
        id: '',
        menuWidth: 0,
        isLoading: false,
        excelBtnOpacity: 0,
        modalActive: false,
        orderInfo: {},
        editRows: {},
        currentCount: 3,
        sort: 'default',
        checked: false,
        menuStatus: null,
        lastEditRowId: null,
        searchResults: [],
        searchSuggestions: [],
        searchTerm: '',
        searchMode: 'free',
        copyOrderData: null,
    }

    constructor(props) {
        super(props);

        this.fetchAll();
        this.checkSortTable = this.checkSortTable.bind(this);
        this.menuWidth = this.menuWidth.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.searchOrders = this.searchOrders.bind(this);
    }

    setCopyOrderData = (order, openModal = true) => {
        this.setState({copyOrderData: order});
        openModal && this.openModal('add-new-order');
    }

    fetchAll = () => {
        this.props.fetchOrders({type: primaryValues.$GET_ORDERS});
        this.props.fetchClubs({type: primaryValues.$GET_CLUBS});
        this.props.fetchUsers({type: primaryValues.$GET_USERS});
        this.props.fetchImagesNames({type: primaryValues.$GET_IMAGES_LIST});
    }

    setSearchResults = (orders) => {
        this.setState({searchResults: orders});
    }

    setSearchMode = (mode) => {
        this.setState({searchMode: mode});
    }

    setSearchSuggestions = (orderField) => {
        this.setSearchMode(orderField);
        const searchBy = orderField.toLowerCase();
        const searchArray = new Set();

        this.props.initialState.orders.forEach(order => searchArray.add(order[searchBy]));
        this.setState({searchSuggestions: [...searchArray].map(value => Object.assign({name: `${value}`}))});
    }

    setSearchTerm = (term) => {
        this.setState({searchTerm: term});
    }

    searchOrders = (term, mode = 'free') => {
        const orders = this.props.initialState.orders;
        const searchArray = [];

        if (term) {
            switch (mode) {
                case '':
                case 'none':
                    break;

                case 'free':
                    const formattedTerm = term.trim().toLowerCase().replace(/[^a-zA-Z0-9\u0590-\u05FF\u0370-\u03FF]/g, '');
                    orders.forEach(order => {
                        for (const key in order) {
                            if (Object.hasOwnProperty.call(order, key)) {
                                const element = `${order[key]}`.trim().toLowerCase().replace(/[^a-zA-Z0-9\u0590-\u05FF\u0370-\u03FF]/g, '');

                                if (element.includes(formattedTerm) && !searchArray.includes(order)) {
                                    searchArray.push(order);
                                }
                            }
                        }
                    });
                    break;

                default:
                    searchArray.push(...orders.filter(order => `${order[mode.toLowerCase()]}`.includes(term)));
                    break;
            }
        }

        this.setSearchResults(searchArray);
    }

    createData = (obj) => {
        let {
            id,
            date,
            order_time,
            club,
            notes,
            admin_notes,
            people,
            name,
            mobile,
            send_mobile,
            email,
            price,
            transfers,
            received_by,
            type,
            confirmed,
            who_put,
            google,
            vip,
            vaucher,
            created_at,
            lang
        } = obj;
        let backColorConfirmed, confirmedValue, googleValue, transfersValue, vipValue, vaucherValue, receivedValue,
            typeValue;

        if (this.props.initialState.excel_file === true) {
            switch (confirmed.toLowerCase()) {
                case 'aprovmed':
                case 'approved':
                    backColorConfirmed = '#b0feb4';
                    break;
                case 'waiting':
                    backColorConfirmed = '#ff9c9c';
                    break;
                case 'sent':
                case 'padding':
                    backColorConfirmed = '#ff9047';
                    break;
                case 'in process':
                    backColorConfirmed = '#ffc0cb';
                    break;
                default:
                    backColorConfirmed = 'white';
                    break;
            }
        } else {
            switch (confirmed.toLowerCase()) {
                case 'aprovmed':
                case 'approved':
                    backColorConfirmed = '#b0feb4';
                    confirmedValue = 'Approved';
                    confirmed = <IoIosMailOpen data-tip={"Approved"} style={{color: 'black', fontSize: 30}}/>;
                    break;
                case 'waiting':
                    backColorConfirmed = '#ff9c9c';
                    confirmedValue = 'Waiting';
                    confirmed = <IoIosMailUnread data-tip={"Waiting"} style={{color: 'black', fontSize: 30}}/>;
                    break;
                case 'in process':
                    backColorConfirmed = '#ffc0cb';
                    confirmedValue = 'In Process';
                    confirmed = <div data-tip={"In Process"}>
                        <IoIosMailUnread style={{color: 'black', fontSize: 30}}/>
                        <IoIosRefresh style={{color: 'black', fontSize: 20}}/>
                    </div>;
                    break;
                case 'sent':
                case 'padding':
                    backColorConfirmed = '#ff9047';
                    confirmedValue = 'Sent';
                    confirmed = <IoIosMail data-tip={"Sent"} style={{color: 'black', fontSize: 30}}/>;
                    break;
                case 'cancel':
                    backColorConfirmed = '#fcffca';
                    confirmedValue = 'Cancel';
                    confirmed = <MdCancel data-tip={"Cancelled"} style={{color: 'black', fontSize: 20}}/>;
                    break;
                default:
                    backColorConfirmed = 'white';
                    confirmedValue = '';
                    confirmed = <MdErrorOutline data-tip={"Unknown status"} style={{color: 'black', fontSize: 20}}/>;
                    break;
            }
        }

        if (this.props.initialState.excel_file !== true)
            switch (google) {
                case 'Yes':
                    googleValue = 'Yes';
                    google = <iconsAi.AiTwotoneLike style={{color: 'black', fontSize: 20}}/>;
                    break;
                case 'No':
                    googleValue = 'No';
                    google = <iconsAi.AiTwotoneDislike style={{color: 'black', fontSize: 20}}/>;
                    break;
                default:
                    googleValue = '';
                    google = '';
            }

        if (this.props.initialState.excel_file !== true)
            switch (vip) {
                case 'Yes':
                    vipValue = 'Yes';
                    vip = <iconsAi.AiTwotoneLike style={{color: 'black', fontSize: 20}}/>;
                    break;
                case 'No':
                    vipValue = 'No';
                    vip = <iconsAi.AiTwotoneDislike style={{color: 'black', fontSize: 20}}/>;
                    break;
                default:
                    vipValue = '';
                    vip = '';
            }

        if (this.props.initialState.excel_file !== true)
            switch (transfers) {
                case 'Yes':
                    transfersValue = 'Yes';
                    transfers = <iconsAi.AiTwotoneLike style={{color: 'black', fontSize: 20}}/>;
                    break;
                case 'No':
                    transfersValue = 'Yes';
                    transfers = <iconsAi.AiTwotoneDislike style={{color: 'black', fontSize: 20}}/>;
                    break;
                default:
                    transfersValue = '';
                    transfers = '';
            }

        if (this.props.initialState.excel_file !== true)
            switch (vaucher) {
                case 'Yes':
                    vaucherValue = 'Yes';
                    vaucher = <iconsAi.AiTwotoneLike style={{color: 'black', fontSize: 20}}/>;
                    break;
                case 'No':
                    vaucherValue = 'No';
                    vaucher = <iconsAi.AiTwotoneDislike style={{color: 'black', fontSize: 20}}/>;
                    break;
                default:
                    vaucherValue = '';
                    vaucher = '';
            }

        if (this.props.initialState.excel_file !== true)
            switch (received_by) {
                case 'System':
                    receivedValue = 'System';
                    received_by = <Icons.MdComputer style={{color: 'black', fontSize: 20}}/>;
                    break;
                case 'Office':
                    receivedValue = 'Office';
                    received_by = <IconsFa.FaPenAlt style={{color: 'black', fontSize: 20}}/>;
                    break;
                default:
                    receivedValue = '';
                    received_by = <MdErrorOutline style={{color: 'black', fontSize: 20}}/>;
            }

        let backColorType;
        if (this.props.initialState.excel_file === true) {
            switch (type) {
                case 'show':
                    typeValue = 'Performances';
                    backColorType = '#d7dbff';
                    break;
                case 'shuttle':
                    typeValue = 'Shuttle';
                    backColorType = '#e3e3e3';
                    break;
                case '1':
                    typeValue = 'New Type';
                    backColorType = '#e3e3e3';
                    break;
                case 'service':
                    typeValue = 'Service';
                    backColorType = '#f7cbb2';
                    break;
                default:
                    typeValue = '';
                    backColorType = 'white';
            }
        } else
            switch (type) {
                case 'show':
                    typeValue = 'Performances';
                    backColorType = '#d7dbff';
                    type = <IconsGi.GiMicrophone style={{color: 'black', fontSize: 20}}/>;
                    break;
                case 'shuttle':
                    typeValue = 'NewType';
                    backColorType = '#f7cbb2';
                    type = <IconsFa.FaBusAlt style={{color: 'black', fontSize: 20}}/>;
                    break;
                case 'transfer':
                    typeValue = 'NewType';
                    backColorType = '#f7cbb2';
                    type = <IconsFa.FaBusAlt style={{color: 'black', fontSize: 20}}/>;
                    break;
                case 'service':
                    typeValue = 'NewType';
                    backColorType = '#f7cbb2';
                    type = <IconsFa.FaBusAlt style={{color: 'black', fontSize: 20}}/>;
                    break;
                default:
                    typeValue = 'Performances';
                    backColorType = '#d7dbff';
                    type = <IconsGi.GiMicrophone style={{color: 'black', fontSize: 20}}/>;
            }

        return {
            id,
            date,
            order_time,
            club,
            notes,
            admin_notes,
            people,
            name,
            mobile,
            send_mobile,
            email,
            price,
            transfers,
            received_by,
            type,
            confirmed,
            who_put,
            google,
            vip,
            vaucher,
            created_at,
            backColorType,
            backColorConfirmed,
            confirmedValue,
            googleValue,
            transfersValue,
            vipValue,
            vaucherValue,
            receivedValue,
            typeValue,
            lang
        };
    }

    getRows = () => {
        return this.createRows(this.state.searchTerm || this.state.searchResults.length > 0 ? this.state.searchResults : this.checkSortTable());
    }

    createRows(rows) {
        return rows.map(row => this.createData({
            id: row.id,
            date: row.date === 'undefined' || row.date.toString().length < 8 ? '' : row.date,
            order_time: row.order_time,
            club: row.club,
            notes: row.notes,
            admin_notes: row.admin_notes,
            people: row.people,
            name: row.name,
            mobile: row.mobile,
            send_mobile: row.send_mobile,
            email: row.email,
            price: row.price,
            transfers: row.transfers,
            received_by: row.received_by,
            type: row.type,
            confirmed: row.confirmed.toLowerCase(),
            who_put: row.who_put,
            google: row.google,
            vip: row.vip,
            vaucher: row.vaucher,
            created_at: row.created_at,
            lang: row.lang
        }));
    }

    componentDidMount() {
        modalServices.newOrder = this.newOrder;
        modalServices.closeNewOrderModalModal = this.closeModal;
        modalServices.openNewOrderModal = this.openNewOrderModal;
    }

    componentDidUpdate() {
        if (this.props.initialState.updateSearchResults && this.state.searchResults.length > 0) {
            const newOrders = this.props.initialState.orders.filter(order1 => {
                return this.state.searchResults.find(order2 => order1.id === order2.id || order1.id === this.props.initialState.updateSearchResults);
            });
            this.setSearchResults(newOrders);
            this.props.initialState.updateSearchResults = false;
        }
    }

    closeModal = () => {
        // modalServices.setCompyOrderData(null)
        // modalServices.isNew = false
        this.setState({checked: !this.state.checked, menuWidth: 0});
    }

    openModal = (type) => {
        if (!['add-new-club', 'add-new-user', 'add-vaucher-image', 'add-new-order'].includes(type)) {
            return;
        }

        if (!this.state.checked) {
            this.setState({menuWidth: 300, checked: !this.state.checked});
        } else if (this.state.menuStatus === type) {
            this.setState({menuWidth: 0, checked: !this.state.checked});
        }

        this.setState({menuStatus: type});
    }

    openNewOrderModal = () => {
        this.openModal('add-new-order');
    }

    menuWidth = () => {
        this.menu = setInterval(() => {
            if (this.state.menuWidth < 300) {
                this.setState({menuWidth: this.state.menuWidth + 1});
            } else
                clearInterval(this.menu);
        }, 1);
    }

    checkDate(string) {
        return ((typeof string === 'string' && /\d{4}-\d{2}-\d{2}/.test(string)) || (typeof string === 'string' && /\d{4}-\d{2}-\d{2} \d{2}-\d{2}-\d{2}/.test(string)));
    }

    checkSortTable = () => {
        let res = this.props.initialState.orders;

        switch (this.state.sort) {
            case 'date-up':
            case 'date-down':
                res = res.map(formatDate).filter(item => item.date !== '01/01/2030');
                res.sort(sortByDate(this.state.sort));
                return res;

            case 'type':
                res.sort((a, b) => {
                    const vala = formatOrderType(a);
                    const valb = formatOrderType(b);
                    return vala.toUpperCase() > valb.toUpperCase() ? -1 : vala.toUpperCase() < valb.toUpperCase() ? 1 : 0;
                });
                return res.reverse();

            case 'id':
            case 'people':
                res.sort(sortByNum(this.state.sort));
                return res.reverse();

            case 'confirmed':
            case 'name':
            case 'email':
                res.sort(sortByLetters(this.state.sort));
                return res;

            case 'received_by':
            case 'transfers':
            case 'who_put':
            case 'google':
            case 'vip':
            case 'vaucher':
            case 'club':
                res.sort(sortByLetters(this.state.sort));
                return res.reverse();

            case 'default':
                return res;

            default:
                return res.reverse();
        }
    }

    newClub = (data) => {
        let params = data;
        this.props.fetchNewClub({type: primaryValues.$GET_NEW_CLUB, params});
        this.closeModal()
    }

    newOrder = (data) => {
        this.props.fetchNewOrder({type: primaryValues.$GET_NEW_ORDER, params: {data}});
        this.setCopyOrderData(null);
        this.closeModal();
    }

    newUser = (data) => {
        let params = data;
        this.props.fetchNewUser({type: primaryValues.$GET_NEW_USER, params});
        this.closeModal()
    }

    vaucherImage = (data) => {
        this.props.initialState.images_list.forEach(element => {
            if (element.name === data.url_image?.toLowerCase()) {
                data.url_image = element.url;
            }
        });
        let params = data;
        this.props.fetchNewVaucherImage({type: primaryValues.$GET_NEW_IMAGE, params});
        this.closeModal()
    }

    actionLastEditRowId = (id) => {
        this.props.fetchLastEditRowId({type: primaryValues.$GET_LAST_ROW_EDIT_ID, id});
    }

    actionLastEditPageIndex = (pageIndex) => {
        this.props.fetchEditPageIndex({type: primaryValues.$GET_EDIT_PAGE_INDEX, pageIndex});
    }

    getTableHeader = () => {
        return (
            <TableHead style={{position: 'sticky', top: 0, background: '#fff'}}>
                <TableRow>
                    <TableCell/>
                    <TableCell data-tip="Filter By Number"
                               style={{cursor: 'pointer', fontWeight: 'bold', margin: 5}}
                               onClick={() => {
                                   this.state.sort !== 'id' ?
                                       this.setState({sort: 'id'}) :
                                       this.setState({sort: 'default'});
                                   this.props.fetchOrders({type: primaryValues.$GET_ORDERS});
                               }} align="center">Order #</TableCell>
                    <TableCell data-tip="Filter By Date"
                               style={{cursor: 'pointer', fontWeight: 'bold', margin: 5}}
                               onClick={() => {
                                   this.state.sort !== 'date-up' && this.state.sort !== 'date-down' ?
                                       this.setState({sort: 'date-up'}) :
                                       this.state.sort !== 'date-down' ?
                                           this.setState({sort: 'date-down'}) :
                                           this.setState({sort: 'default'}, () => this.props.fetchOrders({type: primaryValues.$GET_ORDERS}));
                               }} align="center">{
                        this.state.sort === 'date-up' ?
                            <BiSortUp/> :
                            this.state.sort === 'date-down' ?
                                <BiSortDown/> :
                                this.state.sort === 'date' ?
                                    <BiSort/> : null
                    } Date</TableCell>
                    <TableCell data-tip="Filter By Contact"
                               style={{cursor: 'pointer', fontWeight: 'bold', margin: 5}}
                               onClick={() => {
                                   this.state.sort !== 'club' ?
                                       this.setState({sort: 'club'}) :
                                       this.setState({sort: 'default'});
                                   this.props.fetchOrders({type: primaryValues.$GET_ORDERS});
                               }} align="center">Order Contact</TableCell>
                    <TableCell style={{fontWeight: 'bold', margin: 5}}
                               align="center">Notes</TableCell>
                    <TableCell style={{fontWeight: 'bold', margin: 5}}
                               align="center">Admin Notes</TableCell>
                    <TableCell style={{fontWeight: 'bold', margin: 5}} align="center">Time /
                        People</TableCell>
                    <TableCell data-tip="Filter By Name"
                               style={{cursor: 'pointer', fontWeight: 'bold', margin: 5}}
                               onClick={() => {
                                   this.state.sort !== 'name' ?
                                       this.setState({sort: 'name'}) :
                                       this.setState({sort: 'default'});
                                   this.props.fetchOrders({type: primaryValues.$GET_ORDERS});
                               }} align="center">Name</TableCell>
                    <TableCell style={{fontWeight: 'bold', margin: 5}}
                               align="center">Mobile</TableCell>
                    <TableCell data-tip="Filter By Email"
                               style={{cursor: 'pointer', fontWeight: 'bold', margin: 5}}
                               onClick={() => {
                                   this.state.sort !== 'email' ?
                                       this.setState({sort: 'email'}) :
                                       this.setState({sort: 'default'});
                                   this.props.fetchOrders({type: primaryValues.$GET_ORDERS});
                               }} align="center">Email</TableCell>
                    <TableCell style={{fontWeight: 'bold', margin: 5}}
                               align="center">Price</TableCell>
                    <TableCell data-tip="Filter By Transfers"
                               style={{cursor: 'pointer', fontWeight: 'bold', margin: 5}}
                               onClick={() => {
                                   this.state.sort !== 'transfers' ?
                                       this.setState({sort: 'transfers'}) :
                                       this.setState({sort: 'default'});
                                   this.props.fetchOrders({type: primaryValues.$GET_ORDERS});
                               }} align="center">Transfers</TableCell>
                    <TableCell data-tip="Filter By Received "
                               style={{cursor: 'pointer', fontWeight: 'bold', margin: 5}}
                               onClick={() => {
                                   this.state.sort !== 'received_by' ?
                                       this.setState({sort: 'received_by'}) :
                                       this.setState({sort: 'default'});
                                   this.props.fetchOrders({type: primaryValues.$GET_ORDERS});
                               }} align="center">Received</TableCell>
                    <TableCell data-tip="Filter By Type"
                               style={{cursor: 'pointer', fontWeight: 'bold', margin: 5}}
                               onClick={() => {
                                   this.state.sort !== 'type' ?
                                       this.setState({sort: 'type'}) :
                                       this.setState({sort: 'default'});
                                   this.props.fetchOrders({type: primaryValues.$GET_ORDERS});
                               }} align="center">Type</TableCell>
                    <TableCell data-tip="Filter By Confirmed"
                               style={{cursor: 'pointer', fontWeight: 'bold', margin: 5}}
                               onClick={() => {
                                   this.state.sort !== 'confirmed' ?
                                       this.setState({sort: 'confirmed'}) :
                                       this.setState({sort: 'default'});
                                   this.props.fetchOrders({type: primaryValues.$GET_ORDERS});
                               }} align="center">Confirmed</TableCell>
                    <TableCell data-tip="Filter By Created"
                               style={{cursor: 'pointer', fontWeight: 'bold', margin: 5}}
                               onClick={() => {
                                   this.state.sort !== 'who_put' ?
                                       this.setState({sort: 'who_put'}) :
                                       this.setState({sort: 'default'});
                                   this.props.fetchOrders({type: primaryValues.$GET_ORDERS});
                               }} align="center">Created By</TableCell>
                    <TableCell data-tip="Filter By Google"
                               style={{cursor: 'pointer', fontWeight: 'bold', margin: 5}}
                               onClick={() => {
                                   this.state.sort !== 'google' ?
                                       this.setState({sort: 'google'}) :
                                       this.setState({sort: 'default'});
                                   this.props.fetchOrders({type: primaryValues.$GET_ORDERS});
                               }} align="center">Google</TableCell>
                    <TableCell data-tip="Filter By Vip"
                               style={{cursor: 'pointer', fontWeight: 'bold', margin: 5}}
                               onClick={() => {
                                   this.state.sort !== 'vip' ?
                                       this.setState({sort: 'vip'}) :
                                       this.setState({sort: 'default'});
                                   this.props.fetchOrders({type: primaryValues.$GET_ORDERS});
                               }} align="center">Vip</TableCell>
                    <TableCell data-tip="Filter By Vaucher"
                               style={{cursor: 'pointer', fontWeight: 'bold', margin: 5}}
                               onClick={() => {
                                   this.state.sort !== 'vaucher' ?
                                       this.setState({sort: 'vaucher'}) :
                                       this.setState({sort: 'default'});
                                   this.props.fetchOrders({type: primaryValues.$GET_ORDERS});
                               }} align="center">Vaucher</TableCell>
                    <TableCell style={{fontWeight: 'bold', margin: 5}} align="center">Created
                        Date</TableCell>
                    {/* <TableCell style={{ fontWeight:'bold',margin:5 }} align="center">Created Date</TableCell>  */}
                </TableRow>
            </TableHead>
        )
    }

    render() {
        if (this.props.initialState.orders_success && !this.state.isLoading)
            return (
                <div className='center-app' style={{justifyContent: 'flex-start'}}>
                    <ReactTooltip/>
                    <div style={{
                        width: '100%',
                        fontSize: 12,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        overflow: 'visible'
                    }}>
                        <img onClick={() => window.location.reload()} id="main-img" src={process.env.REACT_APP_HOST_URL + '/public/images/logoE.png'} style={{
                            width: 150,
                            position: 'absolute',
                            left: 20,
                            top: -10,
                            cursor: 'pointer',
                        }} alt='logo'/>
                        <div style={{
                            width: '100%',
                            fontSize: 12,
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                            <div style={{display: 'flex', alignItems: 'center', flexDirection: 'row'}}>
                                {this.state.excelBtnOpacity === 1 &&
                                    <div className="menu-btn" data-place='bottom' data-tip="Download Excel File">
                                        <ReactHTMLTableToExcel
                                            className='export-excel-btn'
                                            table='orders-table'
                                            filename='Orders Nights Greek'
                                            sheet='sheet 1'
                                            buttonText="Export to Excel"
                                        />
                                    </div>
                                }
                                <button className="menu-btn" data-place='bottom' data-tip="Add New Order"
                                        disabled={this.state.modalActive} onClick={() => {
                                    this.openModal('add-new-order');
                                }} style={{margin: 4, paddingRight: 8, paddingLeft: 8}}><IconsFi.FiFilePlus
                                    style={{fontSize: 18}}/></button>
                                <div style={{
                                    opacity: this.state.user_permission == 1 ? 1 : 0.3,
                                    pointerEvents: this.state.user_permission == 1 ? 'auto' : 'none'
                                }}>
                                    <button className="menu-btn" data-place='bottom' data-tip="Add New Contact"
                                            disabled={this.state.modalActive} onClick={() => {
                                        this.openModal('add-new-club');
                                    }} style={{margin: 4, paddingRight: 8, paddingLeft: 8}}><IconsFa.FaRegBuilding
                                        style={{fontSize: 18}}/></button>
                                    <button className="menu-btn" data-place='bottom' data-tip="Add New User"
                                            disabled={this.state.modalActive} onClick={() => {
                                        this.openModal('add-new-user');
                                    }} style={{margin: 4, paddingRight: 8, paddingLeft: 8}}><IconsFa.FaUserPlus
                                        style={{fontSize: 18}}/></button>
                                    <button className="menu-btn" data-place='bottom' data-tip="Add Vaucher Image"
                                            disabled={this.state.modalActive} onClick={() => {
                                        this.openModal('add-vaucher-image');
                                    }} style={{margin: 4, paddingRight: 8, paddingLeft: 8}}><IconsFa.FaTicketAlt
                                        style={{fontSize: 18}}/></button>
                                </div>
                                <button className="menu-btn" data-place='bottom' data-tip="Excel File View"
                                        disabled={this.state.modalActive} onClick={() => {
                                    if (this.props.initialState.excel_file === false) {
                                        this.setState({isLoading: true}, async () => {
                                            setTimeout(() => {
                                                this.props.fetchExcelFile({type: primaryValues.$GET_EXCEL_FILE});
                                                this.setState({excelBtnOpacity: 1}, () => this.setState({isLoading: false}));
                                            }, 500);
                                        });
                                    } else {
                                        this.props.fetchExcelFile({type: primaryValues.$SET_TABLE_VIEW});
                                        this.setState({excelBtnOpacity: 0});
                                    }
                                }} style={{margin: 4, paddingRight: 8, paddingLeft: 8}}><IconsFa.FaFileExcel
                                    style={{fontSize: 18}}/></button>
                                <button className="menu-btn" data-place='bottom' data-tip="Refresh"
                                        disabled={this.state.modalActive}
                                        onClick={() => window.location.reload()}
                                        style={{margin: 4, paddingRight: 8, paddingLeft: 8}}
                                >
                                    <Icons.MdAutorenew style={{fontSize: 18}}/>
                                </button>
                                <div data-place='bottom' data-tip="Filter Orders Mode">
                                    <button id="filter-btn" disabled={true} style={{
                                        margin: 4,
                                        backgroundColor: 'rgba(0,0,0,0)',
                                        border: 0,
                                        color: '#9a1d1d',
                                        fontWeight: 'bold',
                                        textTransform: 'uppercase'
                                    }}>{this.state.sort}</button>
                                </div>
                            </div>
                        </div>
                        <SearchBar {...{
                            actionLastEditPageIndex: this.actionLastEditPageIndex,
                            modalActive: this.state.modalActive,
                            new: true,
                            searchSuggestions: this.state.searchSuggestions,
                            setSearchSuggestions: this.setSearchSuggestions,
                            searchTerm: this.state.searchTerm,
                            setSearchMode: this.setSearchMode,
                            setSearchTerm: this.setSearchTerm,
                            searchOrders: this.searchOrders,
                            hasSearchResults: this.state.searchTerm || this.state.searchResults.length > 0
                        }}/>
                        <div style={{height: this.state.menuWidth, zIndex: 100}}>
                            <Collapse in={this.state.checked}>
                                <div style={{
                                    width: '100vw',
                                    height: '100vh',
                                    position: 'absolute',
                                    top: 100,
                                    left: 0,
                                    zIndex: -1
                                }} onClick={() => this.closeModal()}></div>
                                <Paper elevation={4}>
                                    <div className={'menu-bar'} style={{backgroundColor: '#ebebeb', padding: 10}}>
                                        {this.state.menuStatus === 'add-new-order' &&
                                            <NewOrder copyOrderData={this.state.copyOrderData} setCopyOrderData={this.setCopyOrderData} closeModal={this.closeModal} onClick={this.newOrder}/>}

                                        {this.state.menuStatus === 'add-new-club' &&
                                            <NewClub closeModal={this.closeModal} onClick={this.newClub}/>}

                                        {this.state.menuStatus === 'add-new-user' &&
                                            <NewUser closeModal={this.closeModal} onClick={this.newUser}/>}

                                        {this.state.menuStatus === 'add-vaucher-image' &&
                                            <VaucherImage closeModal={this.closeModal} onClick={this.vaucherImage}/>}
                                    </div>
                                </Paper>
                            </Collapse>
                        </div>

                        <div id={'dash-footer'} style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            gap: '10px',
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            padding: '25px 20px',
                        }}>
                            <p style={{
                                fontSize: 12,
                                maxWidth: 200,
                                margin: 0
                            }}>{`version - ${process.env.REACT_APP_VERSION}`}</p>
                            <Login logout={true}/>
                        </div>
                    </div>
                    <Scrollbars id="scroll-bar">
                        <RowsTable {...{
                            actionLastEditPageIndex: this.actionLastEditPageIndex,
                            lastEditPageIndex: this.props.initialState.lastEditPageIndex,
                            searchTerm: this.state.searchTerm,
                            lastEditRowId: this.props.initialState.lastEditRowId,
                            actionLastEditRowId: this.actionLastEditRowId,
                            isExcelView: this.state.excelBtnOpacity === 1,
                            tableHeader: this.getTableHeader(),
                            getRows: this.getRows,
                            onChange: this.onChange,
                            setIsEditRow: this.setIsEditRow,
                            checked: this.state.checked,
                            searchOrders: this.searchOrders,
                            fetchOrders: this.props.fetchOrders,
                            fetchDeleteOrder: this.props.fetchDeleteOrder,
                            fetchUpdateOrder: this.props.fetchUpdateOrder,
                            fetchSmsMessage: this.props.fetchSmsMessage,
                            fetchGmailMessage: this.props.fetchGmailMessage,
                            fetchWhatsappMessage: this.props.fetchWhatsappMessage,
                            initialState: this.props.initialState,
                            copyOrderData: this.setCopyOrderData,
                        }}/>
                    </Scrollbars>
                </div>
            )
        else {
            return (
                <Scrollbars style={{width: '100%', height: '100%', backgroundColor: 'rgba(0,0,0,0)'}}>
                    <div className='App' style={{backgroundColor: '#181818'}}>
                        <p style={{
                            fontSize: '14px',
                            color: 'white'
                        }}>{this.state.isLoading ? 'Get Ready To Excel Data Table...' : 'Trying to connect the System...'}</p>
                    </div>
                </Scrollbars>
            );
        }
    }
}

const mapStateToProps = state => {
    return {
        initialState: state.initialState,
    };
};
const mapDispachToProps = dispatch => {
    return {
        fetchOrders: (info) => dispatch(info),
        fetchClubs: (info) => dispatch(info),
        fetchUsers: (info) => dispatch(info),
        fetchExcelFile: (info) => dispatch(info),
        fetchImagesNames: (info) => dispatch(info),
        fetchNewUser: (info) => dispatch(info),
        fetchNewClub: (info) => dispatch(info),
        fetchNewOrder: (info) => dispatch(info),
        fetchCancelOrder: (info) => dispatch(info),
        fetchNewVaucherImage: (info) => dispatch(info),
        fetchLastEditRowId: (info) => dispatch(info),
        trigerModalMsg: (info) => dispatch(info),
        fetchWhatsappMessage: (info) => dispatch(info),
        fetchGmailMessage: (info) => dispatch(info),
        fetchSmsMessage: (info) => dispatch(info),
        fetchUpdateOrder: (info) => dispatch(info),
        fetchDeleteOrder: (info) => dispatch(info),
        fetchEditPageIndex: (info) => dispatch(info),
    };
};
export default connect(mapStateToProps, mapDispachToProps)(Center);