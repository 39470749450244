import React from 'react';
import * as Icons from "react-icons/md";
import * as IconsFa from "react-icons/fa";
import * as IconsMi from "react-icons/go";
import * as IconsIo from "react-icons/io";
import {connect} from "react-redux";
import Input from '../components/InputApp';
import Button from '../components/ButtonApp';
import AutocompleteApp from './AutocompleteApp';
import DatePick from '../components/DatePick';
import {Checkbox, FormControlLabel} from "@material-ui/core";
import TimePick from "./TimePick";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import {camelCase, isEqual, startCase} from "lodash";

class NewOrder extends React.Component {
    #initialState = {
        date: '',
        order_time: '',
        club: '',
        notes: '',
        admin_notes: '',
        people: '',
        name: '',
        mobile: '',
        send_mobile: 'No',
        email: '',
        received_by: 'Office',
        confirmed: 'waiting',
        who_put: '',
        transfers: '',
        google: '',
        vip: '',
        vaucher: '',
        lang: 'he',
    };

    constructor(props) {
        super(props);
        this.state = {
            users: this.props.initialState.users,
            error_msg: '',
            data: this.#initialState
        }
        this.changeState = this.changeState.bind(this);
        this.resetForm = this.resetForm.bind(this);
    }

    changeState = (val, label, value) => {
        // alert(val)
        switch (label) {
            case 'Date': //this.state.data.date = val.target.value;
                this.setState({data: {...this.state.data, date: val}})
                break;
            case 'Time': //this.state.data.date = val.target.value;
                this.setState({data: {...this.state.data, order_time: val}})
                break;
            case 'Order Contact': //this.state.data.club = val.target.value;
                this.setState({data: {...this.state.data, club: val}})
                break;
            case 'Notes': //this.state.data.notes = val.target.value;
                this.setState({data: {...this.state.data, notes: val.target.value}})
                break;
            case 'Admin Notes':
                this.setState({data: {...this.state.data, admin_notes: val.target.value}})
                break;
            case 'People': //this.state.data.people = val.target.value;
                this.setState({data: {...this.state.data, people: val.target.value}})
                break;
            case 'Name': //this.state.data.name = val.target.value;
                this.setState({data: {...this.state.data, name: val.target.value}})
                break;
            case 'Mobile':// this.state.data.mobile = val.target.value;
                this.setState({data: {...this.state.data, mobile: val.target.value}})
                break;
            case 'Send Mobile':// this.state.data.mobile = val.target.value;
                this.setState({data: {...this.state.data, send_mobile: val.target.checked ? 'Yes' : 'No'}})
                break;
            case 'Email':// this.state.data.email = val.target.value;
                this.setState({data: {...this.state.data, email: val.target.value}})
                break;
            case 'Transfers': //this.state.data.transfers = val.target.textContent;
                this.setState({data: {...this.state.data, transfers: val.target.textContent}})
                break;
            case 'Received By': //this.state.data.received_by = val.target.textContent;
                this.setState({data: {...this.state.data, received_by: val.target.textContent}})
                break;
            case 'Confirmed': //this.state.data.confirmed = val.target.textContent === 'Waiting' ? 'waiting' : val.target.textContent === 'Sent' ? 'padding' : 'approved';
                this.setState({
                    data: {
                        ...this.state.data,
                        // confirmed: val.target.textContent === 'Waiting' ? 'waiting' : val.target.textContent === 'Sent' ? 'padding' : 'approved'
                        confirmed: val.target.textContent.toLowerCase()
                    }
                })
                break;
            case 'Who Put': //this.state.data.who_put = val.target.textContent;
                this.setState({data: {...this.state.data, who_put: val.target.textContent}})
                break;
            case 'Google': //this.state.data.google = val.target.textContent;
                this.setState({data: {...this.state.data, google: val.target.textContent}})
                break;
            case 'Vip': //this.state.data.vip = val.target.textContent;
                this.setState({data: {...this.state.data, vip: val.target.textContent}})
                break;
            case 'Vaucher': //this.state.data.vaucher = val.target.textContent;
                this.setState({data: {...this.state.data, vaucher: val.target.textContent}})
                break;
            case 'Language':
                this.setState({data: {...this.state.data, lang: value?.id ?? 'he' }})
                break;
            default:
                break;
        }
    }

    onClick = () => {
        this.setState({error_msg: ''});
        let data = this.state.data;
        const valuesFlag = [];

        for (const val in data) {
            // Skip those fields:
            if (['who_put', 'transfers', 'google', 'vip', 'vaucher', 'notes', 'admin_notes', 'order_time', 'send_mobile'].includes(val)) {
                continue;
            }

            if (!`${data[val]}`.trim()) {
                valuesFlag.push(startCase(camelCase(val.replaceAll('_', ' ').replace('club', 'order contact'))));
            }
        }

        if (valuesFlag.length > 0) {
            this.setState({error_msg: 'The following fields are required: ' + valuesFlag.join(', ')});
        } else {
            this.props.onClick(this.state.data);
            this.setState({data: this.#initialState});
        }
    }

    resetForm = () => {
        this.props.setCopyOrderData(null, false);
        this.setState({data: this.#initialState, error_msg: ''});
    }

    setOrderData = () => {
        this.setState({data: Object.assign({}, this.#initialState, this.props.copyOrderData)});
    }

    componentDidMount() {
        if (this.props.copyOrderData !== null) {
            this.setOrderData();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.copyOrderData !== null && !isEqual(this.props.copyOrderData, prevProps.copyOrderData)) {
            this.setOrderData();
        }
    }

    render() {
        return (
            <div id={'new-order-modal'}
                 style={{width: '100%', flexDirection: 'column', display: 'flex', zIndex: 99999}}>
                <p style={{fontSize: 14, color: '#181818', fontWeight: 'bold', textAlign: 'center'}}>New Order</p>
                <div id="add-order"
                     style={{display: 'flex', flexDirection: 'row', alignSelf: 'center', justifyContent: 'center'}}>
                    <div>
                        <DatePick onChange={this.changeState}/>
                        <TimePick onChange={this.changeState}/>
                        {/* <Input icon={<IconsFa.FaRegCalendarAlt style={{color:'#2d2d2d',fontSize:24,padding:0,margin:0,marginRight:5 }}/>}
                          inputColor={'#2d2d2d'} labelColor={'#2d2d2d'} value={this.state.data.date + ''} label={'Date'} type={'text'} onChange={this.changeState} /> */}
                        <div className='input-app'>
                            <div className='input-app-inside'>
                                <IconsFa.FaRegBuilding style={{
                                    color: '#2d2d2d',
                                    fontSize: 24,
                                    padding: 0,
                                    margin: 0,
                                    marginRight: 5
                                }}/>
                                <Autocomplete
                                    freeSolo
                                    style={{width: 250}}
                                    options={this.props.initialState.clubs.map((option) => option.name)}
                                    value={this.state.data.club + ''}
                                    onInputChange={(e, value) => this.changeState(value ?? '', 'Order Contact')}
                                    onChange={(e, value) => this.changeState(value ?? '', 'Order Contact')}
                                    filterOptions={(options, params) => options.filter(option => option.toLowerCase().includes(`${this.state.data.club}`.toLowerCase()))}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            InputLabelProps={{style: {color: '#2d2d2d', fontWeight: 'bold'}}}
                                            label={"Order Contact"}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                        <Input icon={<IconsMi.GoNote
                            style={{color: '#2d2d2d', fontSize: 24, padding: 0, margin: 0, marginRight: 5}}/>}
                               inputColor={'#2d2d2d'} labelColor={'#2d2d2d'} value={this.state.data.notes + ''}
                               label={'Notes'} type={'text'} onChange={this.changeState}/>
                        <Input icon={<IconsIo.IoIosPeople
                            style={{color: '#2d2d2d', fontSize: 24, padding: 0, margin: 0, marginRight: 5}}/>}
                               inputColor={'#2d2d2d'} labelColor={'#2d2d2d'} value={this.state.data.people + ''}
                               label={'People'} type={'text'} onChange={this.changeState}/>
                    </div>
                    <div>
                        <Input icon={<IconsMi.GoPerson
                            style={{color: '#2d2d2d', fontSize: 24, padding: 0, margin: 0, marginRight: 5}}/>}
                               inputColor={'#2d2d2d'} labelColor={'#2d2d2d'} value={this.state.data.name + ''}
                               label={'Name'} type={'text'} onChange={this.changeState}/>
                        <Input icon={<IconsMi.GoDeviceMobile
                            style={{color: '#2d2d2d', fontSize: 24, padding: 0, margin: 0, marginRight: 5}}/>}
                               inputColor={'#2d2d2d'} labelColor={'#2d2d2d'} value={this.state.data.mobile + ''}
                               label={'Mobile'} type={'text'} onChange={this.changeState}/>
                        <Input>
                            <FormControlLabel
                                value={this.state.data.send_mobile + ''}
                                control={<Checkbox checked={this.state.data.send_mobile === 'Yes'}/>}
                                label="Send Mobile"
                                labelPlacement="end"
                                onChange={e => {
                                    this.changeState(e, 'Send Mobile')
                                }}
                            />
                        </Input>
                        <Input icon={<IconsMi.GoMail
                            style={{color: '#2d2d2d', fontSize: 24, padding: 0, margin: 0, marginRight: 5}}/>}
                               inputColor={'#2d2d2d'} labelColor={'#2d2d2d'} value={this.state.data.email + ''}
                               label={'Email'} type={'email'} onChange={this.changeState}/>
                        <label style={{
                            color: '#2d2d2d',
                            margin: '10px 10px -5px',
                            fontSize: '13px'
                        }}>Admin Notes</label>
                        <Input icon={<IconsMi.GoNote
                            style={{color: '#2d2d2d', fontSize: 24, padding: 0, margin: 0, marginRight: 5}}/>}
                               inputColor={'#2d2d2d'} labelColor={'#2d2d2d'} value={this.state.data.admin_notes + ''}
                               label={'Admin Notes'} type={'text'} onChange={this.changeState}/>
                    </div>
                    <div>
                        <AutocompleteApp icon={<Icons.MdDirectionsTransit
                            style={{color: '#2d2d2d', fontSize: 24, padding: 0, margin: 0, marginRight: 5}}/>}
                                         labelColor={'#2d2d2d'} options={[{name: 'Yes'}, {name: 'No'}]}
                                         label={'Transfers'} onChange={this.changeState}/>
                        <AutocompleteApp defaultValue={`Office`} icon={<IconsIo.IoIosSave
                            style={{color: '#181818', fontSize: 24, padding: 0, margin: 0, marginRight: 5}}/>}
                                         labelColor={'#2d2d2d'} options={[{name: 'System'}, {name: 'Office'}]}
                                         label={'Received By'} onChange={this.changeState}/>
                        <AutocompleteApp defaultValue={`waiting`} icon={<IconsFa.FaBookOpen
                            style={{color: '#2d2d2d', fontSize: 24, padding: 0, margin: 0, marginRight: 5}}/>}
                                         labelColor={'#2d2d2d'}
                                         options={[{name: 'Waiting'}, {name: 'In Process'}, {name: 'Sent'}, {name: 'Approved'}]}
                                         label={'Confirmed'} onChange={this.changeState}/>
                        <AutocompleteApp icon={<IconsFa.FaPencilAlt
                            style={{color: '#2d2d2d', fontSize: 24, padding: 0, margin: 0, marginRight: 5}}/>}
                                         labelColor={'#2d2d2d'} options={this.state.users} label={'Who Put'}
                                         onChange={this.changeState}/>
                    </div>
                    <div>
                        <AutocompleteApp icon={<IconsFa.FaGoogle
                            style={{color: '#2d2d2d', fontSize: 24, padding: 0, margin: 0, marginRight: 5}}/>}
                                         labelColor={'#2d2d2d'} options={[{name: 'Yes'}, {name: 'No'}]} label={'Google'}
                                         onChange={this.changeState}/>
                        <AutocompleteApp icon={<IconsIo.IoIosStar
                            style={{color: '#2d2d2d', fontSize: 24, padding: 0, margin: 0, marginRight: 5}}/>}
                                         labelColor={'#2d2d2d'} options={[{name: 'Yes'}, {name: 'No'}]} label={'Vip'}
                                         onChange={this.changeState}/>
                        <AutocompleteApp icon={<Icons.MdSdCard
                            style={{color: '#2d2d2d', fontSize: 24, padding: 0, margin: 0, marginRight: 5}}/>}
                                         labelColor={'#2d2d2d'} options={[{name: 'Yes'}, {name: 'No'}]}
                                         label={'Vaucher'} onChange={this.changeState}/>
                        <AutocompleteApp icon={<IconsFa.FaGlobe
                            style={{color: '#2d2d2d', fontSize: 24, padding: 0, margin: 0, marginRight: 5}}/>}
                                         labelColor={'#2d2d2d'}
                                         options={[
                                             {id: 'he', name: 'Hebrew'},
                                             {id: 'en', name: 'English'},
                                             {id: 'el', name: 'Greek'}
                                         ]}
                                         disableClearable={true}
                                         defaultValue={"he"} label={'Language'}
                                         onChange={this.changeState}/>
                    </div>
                </div>
                {this.state.error_msg &&
                    <p style={{fontSize: 14, color: '#9d1d1d', margin: 0, marginBottom: 15}}>{this.state.error_msg}</p>}
                <div style={{display: 'flex', flexDirection: 'row', gap: 10, justifyContent: 'center', marginTop: 15}}>
                    <Button onClick={this.onClick} name="Save New Order"/>
                    <Button variant="outlined" onClick={this.resetForm} name="Reset"/>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        initialState: state.initialState,
    };
};
const mapDispachToProps = dispatch => {
    return {};
};
export default connect(mapStateToProps, mapDispachToProps)(NewOrder);