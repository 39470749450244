import {put, call} from "redux-saga/effects";
import primaryValues from '../constants/Values.js';

const API_URL = `${process.env.REACT_APP_HOST_URL}${process.env.REACT_APP_API_ENDPOINT}`;

const postRequest = (key, body, url = API_URL) =>
    Promise.race([
        fetch(url + key, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body)
        })
            .then(response => response.json())
            .then(responseJson => responseJson)
            .catch(error => console.debug('Request_error ', error)),
        new Promise((_, reject) => setTimeout(() => reject(new Error('timeout')), 20000))
    ]);

const getRequest = (key, url = API_URL) =>
    Promise.race([
        fetch(url + key).then(response => response.json())
            .then(responseJson => responseJson)
            .catch(error => console.debug('Request_error ', error)),
        new Promise((_, reject) => setTimeout(() => reject(new Error('timeout')), 20000))
    ]);

export function* fetchLogin(action) {
    let key = 'fetchLogin';
    let body = action.params;
    try {
        //console.log('fetchLogin' );
        const response = yield call(postRequest, key, body);
        if (response.success) {
            yield put({type: primaryValues.$LOGIN_RESULT, response});
        } else {
            yield put({type: primaryValues.$LOGIN_ERROR, response});
        }
    } catch (e) {
        //*console.log('fetchLogin error' + e.toString());
    }
}

export function* fetchUserToken(action) {
    let key = 'fetchUserToken';
    let body = action.params;
    try {
        const response = yield call(postRequest, key, body);
        if (response.success) {
            yield put({type: primaryValues.$TOKEN_RESULT});
        } else {
            yield put({type: primaryValues.$TOKEN_ERROR});
        }
    } catch (e) {
    }
}

export function* fetchClubs() {
    let key = 'fetchClubs';
    let body = {};
    try {
        const response = yield call(postRequest, key, body);
        if (response.success) {
            yield put({type: primaryValues.$CLUBS_RESULT, response});
        } else {
            yield put({type: primaryValues.$CLUBS_ERROR});
        }
    } catch (e) {
    }
}

export function* fetchUsersCompany() {
    let action = {};
    let key = 'getUsers';
    key = buildKeysGetRequest(key, action);
    try {
        const response = yield call(getRequest, key);
        if (response.success) {
            yield put({type: primaryValues.$USERS_RESULT, response});
        } else {
            yield put({type: primaryValues.$USERS_ERROR, response});
        }
    } catch (e) {
    }
}

export function* fetchOrders(params) {
    let key = 'fetchOrders';
    let orders = [];
    try {
        const response = yield call(getRequest, key);
        if (response.success) {
            orders = setOrders(response);
            yield put({type: primaryValues.$ORDERS_RESULT, orders});
        } else {
            yield put({type: primaryValues.$ORDERS_ERROR});
        }
    } catch (e) {
    }
}

export function* fetchLastEditRowId(action) {
    action = action.id;
    try {
        yield put({type: primaryValues.$SUCCESS_LAST_ROW_EDIT_ID, id: action});

    } catch (e) {
    }
}

export function* fetchEditPageIndex(action) {
    action = action.pageIndex;
    try {
        yield put({type: primaryValues.$SUCCESS_EDIT_PAGE_INDEX, pageIndex: action});

    } catch (e) {
    }
}

export function* fetchUpdateOrder(action) {
    let key = 'fetchUpdateOrder';
    try {
        const response = yield call(postRequest, key, action.params.data);
        if (response.success) {
            yield put({type: primaryValues.$UPDATE_ORDER_RESULT, response});
        } else {
            yield put({type: primaryValues.$UPDATE_ORDER_ERROR, response});
        }
    } catch (e) {
    }
}

export function* fetchSmsMessage(action) {
    action = action.params;
    let key = 'sendSms';
    key = buildKeysGetRequest(key, action);
    key = key.replace('+', '');
    try {
        const response = yield call(getRequest, key);
        if (response.success) {
            //*console.log('response' , response);
            yield put({type: primaryValues.$SMS_MESSAGE_RESULT, response});
        } else {
            //*console.log('response' , response);
            yield put({type: primaryValues.$SMS_MESSAGE_ERROR, response});
        }
    } catch (e) {
        //*console.log('fetchSmsMessage error' + e.toString());
    }
}

export function* fetchWhatsappMessage(action) {
    action = action.params;
    let key = 'sendWhatsapp';
    key = buildKeysGetRequest(key, action);

    try {
        const response = yield call(getRequest, key);
        if (response.success) {
            yield put({type: primaryValues.$WHATSAPP_MESSEAGE_RESULT, response});
        } else {
            yield put({type: primaryValues.$WHATSAPP_MESSEAGE_ERROR, response});
        }
    } catch (e) {
    }
}

export function* fetchGmailMessage(action) {
    action = action.params;
    let key = 'sendMail';
    key = buildKeysGetRequest(key, action);
    //*console.log(key);
    try {
        const response = yield call(getRequest, key);
        if (response.success) {
            // console.log('fetchGmailMessage' , response);
            yield put({type: primaryValues.$GMAIL_MESSEAGE_RESULT, response});
        } else {
            //*console.log('response' , response);
            // console.log('fetchGmailMessage' , response);
            yield put({type: primaryValues.$GMAIL_MESSEAGE_ERROR, response});
        }
    } catch (e) {
        //*console.log('fetchGmailMessage error' + e.toString());
    }
}

export function* fetchDeleteUser(action) {
    action = action.params;
    let key = 'fetchDeleteUser';
    key = buildKeysGetRequest(key, action);
    try {
        const response = yield call(getRequest, key);
        if (response.success) {
            // console.log('response' , response);
            yield put({type: primaryValues.$GET_USERS});
            yield put({type: primaryValues.$GET_CLUBS});
            yield put({type: primaryValues.$DELETE_USER_RESULT, response});
        } else {
            // console.log('response' , response);
            yield put({type: primaryValues.$DELETE_USER_ERROR, response});
        }
    } catch (e) {
        //*console.log('fetchDeleteOrder error' + e.toString());
    }
}

export function* fetchDeleteClub(action) {
    action = action.params;
    let key = 'fetchDeleteClub';
    key = buildKeysGetRequest(key, action);
    try {
        const response = yield call(getRequest, key);
        if (response.success) {
            //*console.log('response' , response);
            yield put({type: primaryValues.$GET_USERS});
            yield put({type: primaryValues.$GET_CLUBS});
            yield put({type: primaryValues.$DELETE_CLUB_RESULT, response});
        } else {
            //*console.log('response' , response);
            yield put({type: primaryValues.$DELETE_CLUB_ERROR, response});
        }
    } catch (e) {
        //*console.log('fetchDeleteOrder error' + e.toString());
    }
}

export function* fetchDeleteOrder(action) {
    action = action.params;
    let key = 'fetchDeleteOrder';
    key = buildKeysGetRequest(key, action);
    try {
        const response = yield call(getRequest, key);
        if (response.success) {
            //*console.log('response' , response);
            yield put({type: primaryValues.$DELETE_ORDER_RESULT, response});
        } else {
            //*console.log('response' , response);
            yield put({type: primaryValues.$DELETE_ORDER_ERROR, response});
        }
    } catch (e) {
        //*console.log('fetchDeleteOrder error' + e.toString());
    }
}

export function* fetchNewUser(action) {
    let key = 'fetchNewUser';
    const body = action.params;
    try {
        const response = yield call(postRequest, key, body);
        if (response.success) {
            // console.log(' fetchNewUser response' , response);
            yield put({type: primaryValues.$GET_USERS});
            yield put({type: primaryValues.$GET_CLUBS});
            yield put({type: primaryValues.$NEW_USER_RESULT, response});
            // window.location.href = 'https://orders.leylotyavan.co.il'
        } else {
            // console.log('fetchNewUser response' , response);
            yield put({type: primaryValues.$NEW_USER_ERROR, response});
        }
    } catch (e) {
        //*console.log('fetchNewUser error' + e.toString());
    }
}

export function* fetchNewOrder(action) {
    let key = 'fetchNewOrder';
    let body = {data: action.params.data};
    try {
        const response = yield call(postRequest, key, body);
        if (response.success) {
            yield put({type: primaryValues.$NEW_ORDER_RESULT, response});
        } else {
            yield put({type: primaryValues.$NEW_ORDER_ERROR, response});
        }
    } catch (e) {
        //*console.log('fetchNewOrder error' + e.toString());
    }
}

export function* fetchCancelOrder(action) {
    let key = 'fetchCancelOrder';
    let body = action.params;
    try {
        const response = yield call(postRequest, key, body);
        if (response.success) {
            yield put({type: primaryValues.$CANCEL_ORDER_RESULT, response});
        } else {
            yield put({type: primaryValues.$CANCEL_ORDER_ERROR, response});
        }
    } catch (e) {
        //*console.log('fetchNewOrder error' + e.toString());
    }
}

export function* fetchNewClub(action) {
    let data = {data: action.params};
    let key = 'fetchNewClub';
    let body = data;
    try {
        const response = yield call(postRequest, key, body);
        if (response.success) {
            //*console.log('response' , response);
            yield put({type: primaryValues.$GET_USERS});
            yield put({type: primaryValues.$GET_CLUBS});
            yield put({type: primaryValues.$NEW_CLUB_RESULT, response});
        } else {
            //*console.log('response' , response);
            yield put({type: primaryValues.$NEW_ORDER_ERROR, response});
        }
    } catch (e) {
        //*console.log('fetchNewClub error' + e.toString());
    }
}

export function* fetchImagesNames() {
    let key = 'fetchImagesNames';
    let data = {};
    //*console.log('fetchImagesNames');
    try {
        const response = yield call(getRequest, key);
        if (response.success) {
            data.images_list = response.images;
            data.images = setImagesName(response);
            data.url_selected = setUrlSelected(response);
            data.url_selected_en = setUrlSelected(response, 'en');
            data.url_selected_el = setUrlSelected(response, 'el');
            yield put({type: primaryValues.$IMAGES_LIST_RESULT, data});
        } else {
            //    console.log('response' , response);
            yield put({type: primaryValues.$IMAGES_LIST_ERROR, response});
        }
    } catch (e) {
        //*console.log('fetchImagesNames error' + e.toString());
    }
}

export function* fetchNewVaucherImage(action) {
    action = action.params;
    //*console.log('fetchNewVaucherImage',action);
    let key = 'fetchNewVaucherImage';
    let body = action;
    try {
        const response = yield call(postRequest, key, body);
        if (response.success) {
            // console.log('response' , response);
            yield put({type: primaryValues.$NEW_IMAGE_RESULT, response});
        } else {
            // console.log('response' , response);
            yield put({type: primaryValues.$NEW_IMAGE_ERROR, response});
        }
    } catch (e) {
        //*console.log('fetchNewClub error' + e.toString());
    }
}

export function* fetchSendVaucherToCustomer(action) {
    action = action.params;
    console.log('fetchNewVaucherImage', action);
    let key = 'sendVaucherToCustomer';
    let body = action;
    try {
        const response = yield call(postRequest, key, body);
        console.log('response', response);
        if (response.success) {
            yield put({type: primaryValues.$SEND_VAUCHER_CUSTOMER_RESULT, response});
        } else {
            // console.log('response' , response);
            yield put({type: primaryValues.$SEND_VAUCHER_CUSTOMER_RESULT, response});
        }
    } catch (e) {
        //*console.log('fetchNewClub error' + e.toString());
    }
}

export function* fetchExcelFile() {
    //*console.log('fetchExcelFile');
}

function wordsReverser(str) {
    return str.replace(/[a-zA-Z]+/gm, function (item) {
        return item.split('').reverse().join('');
    });
}

const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
}

function setOrders(response) {
    let orders = [];

    for (let i = 0; i < response.orders.length; i++) {
        if (response.orders[i].active === '1' || response.orders[i].active === 1) {
            orders.push(response.orders[i]);
        }
    }

    orders.map(order => (
        order.notes = order.notes.indexOf('%') !== -1 ? setUncodedUtf8(order.notes, 'code') : order.notes,
            order.date = order.notes.indexOf('-2020') !== -1 ? order.date + ' \n*' + order.notes : order.date,
            order.date = order.date.replace(/-/g, '/'),
            // order.name = order.name.replace(/-/g, '/'),
            order.notes = order.notes.indexOf('-2020') !== -1 ? '' : order.notes,
            // order.club = order.club.replace(/[^A-Za-z']/g, ''),
            order.email = order.email.replace('%40', '@'),
            order.name = order.name.replace(/[+]/g, ' '),
            order.name = capitalize(order.name),
            order.received_by = setReceived(order.received_by),
            order.form_id = order.form_id.indexOf('%') !== -1 ? order.notes = order.form_id : order.form_id,
            order.form_id = order.form_id.match(/[A-Za-z]/g) ? order.notes = order.form_id : order.form_id,
            order.created_at = order.created_at.replace(/-/g, '/')
    ));

    orders.map(order => {
        const processedOrder = { ...order };

        // Process notes
        if (processedOrder.notes.includes('%')) {
            processedOrder.notes = setUncodedUtf8(processedOrder.notes, 'code');
        }

        // Process date
        if (processedOrder.notes.includes('-2020')) {
            processedOrder.date = `${processedOrder.date.replace(/-/g, '/')} \n*${processedOrder.notes}`;
            processedOrder.notes = '';
        } else {
            processedOrder.date = processedOrder.date.replace(/-/g, '/');
        }

        // Process email
        processedOrder.email = processedOrder.email.replace('%40', '@');

        // Process name
        processedOrder.name = capitalize(processedOrder.name.replace(/[+]/g, ' '));

        // Process received_by
        processedOrder.received_by = setReceived(processedOrder.received_by);

        // Process form_id
        if (processedOrder.form_id.includes('%') || processedOrder.form_id.match(/[A-Za-z]/g)) {
            processedOrder.notes = processedOrder.form_id;
        }

        // Process created_at
        processedOrder.created_at = processedOrder.created_at.replace(/-/g, '/');

        return processedOrder;
    });

    // console.debug('setOrders',orders)
    return orders;
}

function setUrlSelected(response, lang = '') {
    lang = lang ? '_' + lang : '';

    return response.images.find(image => image.name === 'url_selected' + lang)?.url;
}

function setImagesName(response) {
    return response.images.filter(image => !image.name.includes('url_selected'));
}

function setReceived(type) {
    switch (type) {
        case '1':
            return 'System';
        case '2':
            return 'Office';
        case 'System':
            return 'System';
        case 'Office':
            return 'Office';
        default:
            return 'Null';

    }
}

function setUncodedUtf8(val, position) {
    let words = '';
    words = val.split('%');
    let newHebWord = '';
    let temp = '';
    for (let i = 0; i < words.length; i++) {
        if (i >= 1 && i % 2 === 1) {
            temp = getCharCode(words[i + 1], position);
            newHebWord += temp === undefined ? '' : temp;
        }
    }
    return newHebWord;
}

function getCharCode(char, position) {
    let codes = ['90', '91', '92', '93', '94', '95', '96', '97', '98', '99', '9A', '9B', '9C', '9D', '9E', '9F', 'A0', 'A1', 'A2', 'A3', 'A4', 'A5', 'A6', 'A7', 'A8', 'A9', 'AA'];
    let hebChars = ['ת', 'ש', 'ר', 'ק', 'צ', 'ץ', 'פ', 'ף', 'ע', 'ס', 'נ', 'ן', 'מ', 'ם', 'ל', 'כ', 'ך', 'י', 'ט', 'ח', 'ז', 'ו', 'ה', 'ד', 'ג', 'ב', 'א'];
    let spaceFlag = false;
    hebChars = hebChars.reverse();
    if (position === 'code')
        if (char && char.indexOf('+') !== -1) {
            char = char.replace('+', '');
            spaceFlag = true;
        } else if (position === 'uncode')
            if (char && char.indexOf(' ') !== -1)
                char = char.replace(' ', '+');
    for (let i = 0; i < codes.length; i++)
        if (position === 'code')
            if (char === codes[i])
                return spaceFlag === true ? hebChars[i] + ' ' : hebChars[i];
            else if (position === 'uncode')
                if (char === hebChars[i])
                    return codes[i];

}

function buildKeysGetRequest(key, params) {
    let keys = key;
    let startFlag = false;
    for (let key in params) {
        if (params.hasOwnProperty(key)) {
            if (!startFlag) {
                keys += '?';
                startFlag = true;
            } else
                keys += '&';
            keys += key + '=' + params[key];
        }
    }
    return keys;
}

export async function copyStringToClipboard(string) {
    try {
        await copyToClipboard(string);
        console.log('Text copied to the clipboard!');
    } catch (error) {
        console.error(error);
    }
}

async function copyToClipboard(textToCopy) {
    // Navigator clipboard api needs a secure context (https)
    if (navigator.clipboard && window.isSecureContext) {
        await navigator.clipboard.writeText(textToCopy);
    } else {
        // Use the 'out of viewport hidden text area' trick
        const textArea = document.createElement("textarea");
        textArea.value = textToCopy;

        // Move textarea out of the viewport so it's not visible
        textArea.style.position = "absolute";
        textArea.style.left = "-999999px";

        document.body.prepend(textArea);
        textArea.select();

        try {
            document.execCommand('copy');
        } catch (error) {
            console.error(error, 'copy to clipboard error');
        } finally {
            textArea.remove();
        }
    }
}