export const primaryValues = {
    $GET_LOGOUT: 'GET_LOGOUT',
    $GET_LOGIN: 'GET_LOGIN',
    $LOGIN_RESULT: 'LOGIN_RESULT',
    $LOGIN_ERROR: 'LOGIN_ERROR',
    $GET_TOKEN: 'GET_TOKEN',
    $TOKEN_RESULT: 'TOKEN_RESULT',
    $TOKEN_ERROR: 'TOKEN_ERROR',
    $GET_ORDERS: 'GET_ORDERS',
    $ORDERS_RESULT: 'ORDERS_RESULT',
    $ORDERS_REFRESH: 'ORDERS_REFRESH',
    $ORDERS_ERROR: 'ORDERS_ERROR',
    $GET_CLUBS: 'GET_CLUBS',
    $CLUBS_RESULT: 'CLUBS_RESULT',
    $CLUBS_ERROR: 'CLUBS_ERROR',
    $GET_USERS: 'GET_USERS',
    $USERS_RESULT: 'USERS_RESULT',
    $USERS_ERROR: 'USERS_ERROR',
    $GET_WHATSAPP_MESSEAGE: 'GET_WHATSAPP_MESSEAGE',
    $WHATSAPP_MESSEAGE_RESULT: 'WHATSAPP_MESSEAGE_RESULT',
    $WHATSAPP_MESSEAGE_ERROR: 'WHATSAPP_MESSEAGE_ERROR',
    $GET_GMAIL_MESSEAGE: 'GET_GMAIL_MESSEAGE',
    $GMAIL_MESSEAGE_RESULT: 'GMAIL_MESSEAGE_RESULT',
    $GMAIL_MESSEAGE_ERROR: 'GMAIL_MESSEAGE_ERROR',
    $GET_VIBER_MESSEAGE: 'GET_VIBER_MESSEAGE',
    $VIBER_MESSEAGE_RESULT: 'VIBER_MESSEAGE_RESULT',
    $VIBER_MESSEAGE_ERROR: 'VIBER_MESSEAGE_ERROR',
    $GET_UPDATE_ORDER: 'GET_UPDATE_ORDER',
    $UPDATE_ORDER_RESULT: 'UPDATE_ORDER_RESULT',
    $UPDATE_ORDER_ERROR: 'UPDATE_ORDER_ERROR',
    $GET_DELETE_ORDER: 'GET_DELETE_ORDER',
    $DELETE_ORDER_RESULT: 'DELETE_ORDER_RESULT',
    $DELETE_ORDER_ERROR: 'DELETE_ORDER_ERROR',
    $GET_NEW_USER: 'GET_NEW_USER',
    $NEW_USER_RESULT: 'NEW_USER_RESULT',
    $GET_EDIT_PAGE_INDEX: 'GET_EDIT_PAGE_INDEX',
    $NEW_USER_ERROR: 'NEW_USER_ERROR',
    $GET_NEW_ORDER: 'GET_NEW_ORDER',
    $NEW_ORDER_RESULT: 'NEW_ORDER_RESULT',
    $NEW_ORDER_ERROR: 'NEW_ORDER_ERROR',
    $GET_CANCEL_ORDER: 'GET_CANCEL_ORDER',
    $CANCEL_ORDER_RESULT: 'CANCEL_ORDER_RESULT',
    $CANCEL_ORDER_ERROR: 'CANCEL_ORDER_ERROR',
    $GET_NEW_CLUB: 'GET_NEW_CLUB',
    $NEW_CLUB_RESULT: 'NEW_CLUB_RESULT',
    $NEW_CLUB_ERROR: 'NEW_CLUB_ERROR',
    $GET_EXCEL_FILE: 'GET_EXCEL_FILE', 
    $SET_TABLE_VIEW: 'SET_TABLE_VIEW',
    $GET_IMAGES_LIST: 'GET_IMAGES_LIST',
    $IMAGES_LIST_RESULT: 'IMAGES_LIST_RESULT',
    $IMAGES_LIST_ERROR: 'IMAGES_LIST_ERROR',
    $GET_NEW_IMAGE: 'GET_NEW_IMAGE',
    $GET_LAST_ROW_EDIT_ID: 'GET_LAST_ROW_EDIT_ID',
    $SUCCESS_LAST_ROW_EDIT_ID: 'SUCCESS_LAST_ROW_EDIT_ID',
    $SUCCESS_EDIT_PAGE_INDEX: 'SUCCESS_EDIT_PAGE_INDEX',
    $NEW_IMAGE_RESULT: 'NEW_IMAGE_RESULT',
    $NEW_IMAGE_ERROR: 'NEW_IMAGE_ERROR',
    $GET_SMS_MESSAGE: 'GET_SMS_MESSAGE',
    $SMS_MESSAGE_RESULT: 'SMS_MESSAGE_RESULT',
    $SMS_MESSAGE_ERROR: 'SMS_MESSAGE_ERROR',
    $GET_DELETE_USER: 'GET_DELETE_USER',
    $DELETE_USER_RESULT: 'DELETE_USER_RESULT',
    $DELETE_USER_ERROR: 'DELETE_USER_ERROR',
    $GET_DELETE_CLUB: 'GET_DELETE_CLUB',
    $DELETE_CLUB_RESULT: 'DELETE_CLUB_RESULT',
    $DELETE_CLUB_ERROR: 'DELETE_CLUB_ERROR',
    $GET_SEND_VAUCHER_CUSTOMER: 'GET_SEND_VAUCHER_CUSTOMER',
    $SEND_VAUCHER_CUSTOMER_RESULT: 'SEND_VAUCHER_CUSTOMER_RESULT' 
}
export default primaryValues;









