import React, {useState} from 'react';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import * as IconsFa from "react-icons/fa";
import moment from 'moment';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
} from '@material-ui/pickers';

export default function TimePick(props) {
    const value = props.value ? new Date(`0-${props.value}`): null;
    const [selectedTime, handleTimeChange] = useState(value);

    const onChangeTime = (time) => {
        if (moment(time).isValid() || time === null) {
            console.log(time, 'time is valid');
            handleTimeChange(time);
            const s = time?.toLocaleTimeString('he-IL', {hour12: false, hour: '2-digit', minute: '2-digit'});
            props.onChange(s, 'Time');
        }
    }

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                margin: 0,
                paddingRight: 10,
                paddingLeft: 10
            }}>
                <IconsFa.FaRegClock style={{
                    color: '#2d2d2d',
                    fontSize: 24,
                    padding: 0,
                    margin: 0,
                    marginRight: 5
                }}/>
                <KeyboardTimePicker
                    disableToolbar
                    variant="inline"
                    format="HH:mm"
                    margin="normal"
                    id="time-picker-inline"
                    label="Time"
                    value={selectedTime}
                    onChange={onChangeTime}
                    KeyboardButtonProps={{
                        'aria-label': 'change time',
                    }}
                />
            </div>
        </MuiPickersUtilsProvider>
    );
}